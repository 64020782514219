/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { TableProps } from '@cloudscape-design/components';
import { useState, useEffect, useContext } from 'react';
import { IHighlight } from 'react-pdf-highlighter';
import { ApplicantDetailContext } from './ApplicantDetailContext';
import { CreateHighlightFromOffset } from './CreateHighlightFromOffset';

import MedicalConditionsTable, { MedicalProps, toTitleCase } from './MedicalConditionsTable';
interface PhysicianProps extends MedicalProps {
  Specialty: string;
}
export const Physicians = () => {
  // state
  const [MedicalConditions, setMedicalConditions] = useState<PhysicianProps[]>();
  const { TextractMapping, AddHighlights } = useContext(ApplicantDetailContext);

  useEffect(() => {
    const getComprehendClassification = async () => {
      const highlights: IHighlight[] = [];

      const medicationConditions = TextractMapping?.Physicians.map((entity: any) => {
        const highlight = CreateHighlightFromOffset(entity, 'Physician');
        if (highlight) highlights.push(highlight);
        return {
          Text: entity.Text,
          Category: entity.Category,
          Specialty: entity.Specialty,
          Score: entity.Score,
          HighlightId: highlight?.id,
        };
      });
      medicationConditions?.sort((a, b): number => b.Score - a.Score);
      setMedicalConditions(medicationConditions);
      AddHighlights(highlights);
    };
    getComprehendClassification();
  }, [TextractMapping]);

  // table column definitions
  const columnDefinitions: TableProps.ColumnDefinition<PhysicianProps>[] = [
    {
      id: 'Text',
      header: 'Name',
      cell: (item: PhysicianProps) => toTitleCase(item.Text),
      minWidth: 50,
    },
    {
      id: 'Specialty',
      header: 'Specialty',
      cell: (item: PhysicianProps) => item.Specialty,
      minWidth: 180,
    },
    {
      id: 'Confidence',
      header: 'Confidence',
      cell: (item: PhysicianProps) => `${(item.Score * 100).toFixed(2)}%`,
      minWidth: 180,
    },
  ];

  return (
    <MedicalConditionsTable
      MedicalConditions={MedicalConditions}
      title="Physicians"
      columnDefinitions={columnDefinitions}
    />
  );
};
