/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
// Polaris Components
import { AppLayout, Alert, Table, Header, TableProps, Link, Box } from '@cloudscape-design/components';

// router
import { useNavigate } from 'react-router-dom';
import { routeNames, routes } from '../../common/route';

// interfaces
import { AppInterface, AppEnums } from '@aws-prototype/shared';

// React query
import { useClaim } from '../../hooks/useClaim';
import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';

const Home = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  // RQ custom hook
  const { data, isError, isFetching } = useClaim(); // get claims

  // table column definitions
  const columnDefinitions: TableProps.ColumnDefinition<AppInterface.Claim>[] = [
    {
      id: 'sortKey',
      sortingField: 'sortKey',
      header: 'APS Document ID',
      cell: (item: AppInterface.Claim) =>
        item.processingStatus === AppEnums.Status.SUCCESS ? (
          <Link onFollow={() => navigate(routes[routeNames.Claim].href.replace(':claimid', item.hashKey))}>
            {item.hashKey + '/' + item.sortKey}
          </Link>
        ) : (
          item.hashKey + '/' + item.sortKey
        ),
      minWidth: 160,
    },
    {
      id: 'dateUploaded',
      sortingField: 'dateUploaded',
      header: 'Date Uplaoded',
      cell: (item: any) => new Date(item.uploadedAt).toLocaleString(),
      minWidth: 100,
    },
    {
      id: 'status',
      sortingField: 'status',
      header: 'Status',
      cell: (item: AppInterface.Claim | any) =>
        item.processingStatus !== AppEnums.Status.SUCCESS ? item.processingStatus : item.claimStatus,
      minWidth: 100,
    },
    {
      id: 'reviewStatus',
      sortingField: 'reviewStatus',
      header: 'Review Status',
      cell: (item: AppInterface.Claim) => item.reviewStatus,
      minWidth: 100,
    },
    {
      id: 'confidenceScore',
      sortingField: 'confidenceScore',
      header: 'Confidence Score',
      cell: (item: AppInterface.Claim) =>
        item.confidenceScore ? (Number.parseFloat(item.confidenceScore) * 100).toFixed(2) + '%' : '',
      minWidth: 100,
    },
  ];

  return (
    <AppLayout
      contentType="wizard"
      minContentWidth={width - 300}
      // disableContentPaddings
      navigationHide
      toolsHide
      content={
        <Table
          header={
            <Header variant="awsui-h1-sticky" counter={data && `(${data.length.toString()})`}>
              APS Documents
            </Header>
          }
          columnDefinitions={columnDefinitions}
          items={data}
          sortingDisabled
          variant="full-page"
          stickyHeader
          resizableColumns
          loading={isFetching}
          loadingText="Loading insurance claims"
          empty={
            isError ? (
              <Alert type="error" header="Unable to fetch Claims">
                We are unable to fetch the list of applicants at this time. Please try again later.
              </Alert>
            ) : (
              <Box textAlign="center" color="inherit">
                <b>No applicants to display</b>
              </Box>
            )
          }
        />
      }
    />
  );
};

export default Home;
