/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { ComprehendMedicalTextractMapping } from '@aws-prototype/shared/dist/libs/data-access/interfaces';
import { createContext } from 'react';
import { IHighlight } from 'react-pdf-highlighter';

export interface EntityMapping {
  Medications: ComprehendMedicalTextractMapping[];
  MedicalConditions: ComprehendMedicalTextractMapping[];
  Physicians: ComprehendMedicalTextractMapping[];
  TestTreatments: ComprehendMedicalTextractMapping[];
}
export interface ApplicantDetailData {
  TextractMapping: EntityMapping | undefined;
  SetSelectedHighlightId?: (highlightId: string) => void;
  SelectedHighlightId?: string;
  Highlights: IHighlight[];
  AddHighlights: (items: IHighlight[]) => void;
}
export const ApplicantDetailContext = createContext<ApplicantDetailData>({
  TextractMapping: {
    Medications: [],
    MedicalConditions: [],
    Physicians: [],
    TestTreatments: [],
  },
  SetSelectedHighlightId: () => {},
  Highlights: [],
  SelectedHighlightId: undefined,
  AddHighlights: () => {},
});
