/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
export enum Status {
  ERROR = 'Error',
  WARNING = 'Warning',
  SUCCESS = 'Success',
  IN_PROGRESS = 'In Progress',
}

export enum ClaimStates {
  PENDING_REVIEW = 'Pending Review',
  REVIEWED = 'Reviewed',
  /// the ones after this are for future use
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  AUTO_APPROVED = 'Auto Approved',
  AUTO_REJECTED = 'Auto Rejected',
  LOCKED = 'Locked',
}

// this could later be file MIME types
export enum ClaimFileType {
  pdf = 'pdf',
  jpg = 'jpg',
  png = 'png',
  doc = 'doc',
  xls = 'xls',
}

export enum ClaimFeatures {
  insurerID = 'insurerID',
  address = 'address',
  name = 'name',
  claimAmount = 'claimAmount',
}

export enum DocumentOutput {
  WORDS = 'WORDS',
  LINES = 'LINES',
  TABLES = 'TABLES',
  FORMS = 'FORMS',
  ID = 'ID',
  EXPENSE_LINE_ITEMS = 'EXPENSE_LINE_ITEMS',
  EXPENSE_SUMMARY_FIELDS = 'EXPENSE_SUMMARY_FIELDS',
  ENTITIES = 'ENTITIES',
  CUSTOM_ENTITIES = 'CUSTOM_ENTITIES',
  PII_ENTITIES = 'PII_ENTITIES',
  ICD10CM = 'ICD10CM',
  MEDICAL_ENTITIES = 'MEDICAL_ENTITIES',
  COMPREHEND_MAPPING = 'COMPREHEND_MAPPING',
}

export enum ReviewStatus {
  GOOD = 'Good',
  INTERMEDIATE = 'Intermediate',
  BAD = 'Bad',
}

export enum ClaimDocTypes {
  CMS1500 = 'CMS1500',
  DISCHARGE_SUMMARY = 'DISCHARGE_SUMMARY',
  INSURANCE_ID = 'INSURANCE_ID',
  INVOICE_RECEIPT = 'INVOICE_RECEIPT',
  MEDICAL_TRANSCRIPTION = 'MEDICAL_TRANSCRIPTION',
  LICENSE = 'LICENSE',
  PASSPORT = 'PASSPORT',
  UNKNOWN = 'UNKNOWN',
  APS = 'APS',
}

export enum ClaimDocTypesValues {
  CMS1500 = 'CMS1500 Claim Form',
  DISCHARGE_SUMMARY = 'Discharge Summary',
  INSURANCE_ID = 'Insurance ID',
  INVOICE_RECEIPT = 'Invoice',
  MEDICAL_TRANSCRIPTION = 'Medical Transcription',
  LICENSE = 'Drivers License',
  PASSPORT = 'Passport',
  UNKNOWN = 'Unknown Type',
  APS = 'Attending Physician Statement',
}
