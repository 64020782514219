/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { SideNavigationProps } from '@cloudscape-design/components/side-navigation';

const APP_NAME = 'IDP Demo';

export enum routeNames {
  Home = 'Home',
  Claim = 'Claim',
}

export const routes = {
  [routeNames.Home]: {
    text: routeNames.Home,
    href: '/',
  },
  [routeNames.Claim]: {
    text: routeNames.Home,
    href: '/claim/:claimid',
  },
};

export const navItems: SideNavigationProps.Item[] = [
  { type: 'link', text: routes[routeNames.Home].text, href: routes[routeNames.Home].href },
];

export const adminNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section',
    text: 'Admin Controls',
    items: [
      { type: 'link', text: 'Demo Setup', href: '#/howto' },
      { type: 'link', text: 'User Setup', href: '#/origin' },
      // { type: 'link', text: routes[routeNames.Healthcare].text, href: routes[routeNames.Healthcare].href },
    ],
  },
];

export const navHeader = { text: APP_NAME, href: routes[routeNames.Home].href };
