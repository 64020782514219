/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { AppInterface, AppEnums } from '@aws-prototype/shared';

// date time
import { DateTime } from 'luxon';

const getClaims = async () => {
  try {
    const response = await API.get('IdpApi', 'claims', {});
    return response
      .filter((resp: any) => !resp.sortKey.includes('BR'))
      .sort((a: AppInterface.Claim, b: AppInterface.Claim) =>
        a.processingStatus === AppEnums.ClaimStates.PENDING_REVIEW ? -1 : 1,
      );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getClaimDocs = async (hashKey: string) => {
  try {
    const response = await API.get('IdpApi', 'claims', { queryStringParameters: { hashKey } });
    const arr_resp = response
      .filter((resp: any) => !resp.sortKey.includes('BR') && resp.hashKey === hashKey)
      .map((item: AppInterface.ClaimDoc) => ({
        hashKey: item.hashKey,
        sortKey: item.sortKey,
        documentType: {
          Score: item.documentType.Score,
          Name: item.documentType.Name,
        },
        onLifeSavingMedication: item.onLifeSavingMedication,
        recentProcedure: item.recentProcedure,
        reviewStatus: item.reviewStatus,
        claimStatus: item.claimStatus,
        uploadedAt: DateTime.fromISO(item.uploadedAt).toLocaleString(DateTime.DATE_MED),
        dateRange: {
          minDate: new Date(item.dateRange.minDate).toLocaleDateString(),
          maxDate: new Date(item.dateRange.maxDate).toLocaleDateString(),
        },
        // fileType: item.sortKey.split('.').pop(),
        fileSize: `${Math.ceil(item.fileSize / 1000)} KB`,
        ...(item.confidenceScore && { confidenceScore: item.confidenceScore }),
        uploadLocation: item.uploadLocation,
        ...(item.textractAnalyzeIDOutput && { textractAnalyzeIDOutput: item.textractAnalyzeIDOutput }),
        ...(item.textractDetectTextOutput && { textractDetectTextOutput: item.textractDetectTextOutput }),
        ...(item.textractDocumentAnalysisOutput && {
          textractDocumentAnalysisOutput: item.textractDocumentAnalysisOutput,
        }),
        ...(item.textractExpenseAnalysisOutput && {
          textractExpenseAnalysisOutput: item.textractExpenseAnalysisOutput,
        }),
        ...(item.textractPlainTextOutput && { textractPlainTextOutput: item.textractPlainTextOutput }),
        ...(item.comprehendClassificationOutput && {
          comprehendClassificationOutput: item.comprehendClassificationOutput,
        }),
        ...(item.comprehendCustomEntitiesOutput && {
          comprehendCustomEntitiesOutput: item.comprehendCustomEntitiesOutput,
        }),
        ...(item.comprehendEntitiesOutput && { comprehendEntitiesOutput: item.comprehendEntitiesOutput }),
        ...(item.comprehendMedicalICD10CMOutput && {
          comprehendMedicalICD10CMOutput: item.comprehendMedicalICD10CMOutput,
        }),
        ...(item.comprehendPiiEntitiesOutput && { comprehendPiiEntitiesOutput: item.comprehendPiiEntitiesOutput }),
        ...(item.comprehendMedicalEntityOutput && {
          comprehendMedicalEntityOutput: item.comprehendMedicalEntityOutput,
        }),
        ...(item.comprehendMedicalEntityTextractMappingOutput && {
          comprehendMedicalEntityTextractMappingOutput: item.comprehendMedicalEntityTextractMappingOutput,
        }),
      }));
    return arr_resp;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getBusinessRules = async (hashKey: string) => {
  try {
    const response = await API.get('IdpApi', 'claims', { queryStringParameters: { hashKey } });
    return response.filter((resp: any) => resp.sortKey.includes('BR'));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getAnalyzeDoc = async (hashKey: string, sortKey: string, output: AppEnums.DocumentOutput) => {
  try {
    return await API.get('IdpApi', `document?hashKey=${hashKey}&sortKey=${sortKey}&output=${output}`, {});
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useClaim = () => {
  return useQuery(['idp-insurance-claims'], () => getClaims());
};

export const useClaimDocs = (hashKey: string) => {
  return useQuery(['idp-insurance-docs'], () => getClaimDocs(hashKey), {
    refetchOnWindowFocus: false, // avoid unnecessary re-fetches
  });
};

export const useClaimBR = (hashKey: string) => {
  return useQuery(['idp-insurance-BRS'], () => getBusinessRules(hashKey), {
    refetchOnWindowFocus: false, // avoid unnecessary re-fetches
  });
};

export const useClaimAnalyzeDoc = (hashKey: string, sortKey: string, output: AppEnums.DocumentOutput) => {
  return useQuery([`idp-insurance-Analyze-${sortKey}-${output}`], () => getAnalyzeDoc(hashKey, sortKey, output));
};
