/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { useContext, useEffect, useState } from 'react';

// Polaris Components
import { Table, Spinner, Header, TableProps, Button } from '@cloudscape-design/components';
import { ApplicantDetailContext } from './ApplicantDetailContext';

export interface MedicalConditionsTableProps {
  title: string;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  MedicalConditions?: MedicalProps[];
}

export interface MedicalProps {
  Attributes?: any;
  Text: string;
  Category: string;
  Score: number;
  HighlightId: string | undefined;
}

export const toTitleCase = (text: string) => {
  return text
    ?.toLowerCase()
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

const MedicalConditionsTable = (props: MedicalConditionsTableProps) => {
  const { SetSelectedHighlightId } = useContext(ApplicantDetailContext);
  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<any>[]>();

  useEffect(() => {
    const onClick = (item: MedicalProps) => {
      if (!item.HighlightId) return;
      document.location.hash = `highlight-${item.HighlightId}`;
      SetSelectedHighlightId?.(item.HighlightId);
    };
    const refColumn = {
      id: 'ref',
      header: '',
      cell: (item: MedicalProps) => <Button iconName="external" variant="icon" onClick={() => onClick(item)} />,
      maxWidth: 30,
    };
    setColumnDefinitions([refColumn, ...props.columnDefinitions]);
  }, [SetSelectedHighlightId, props.columnDefinitions]);
  return (
    <div style={{ padding: 10 }}>
      {props.MedicalConditions ? (
        <Table
          columnDefinitions={columnDefinitions!}
          items={props.MedicalConditions}
          stickyHeader
          wrapLines
          variant="container"
          header={
            <Header counter={props.MedicalConditions.length ? '(' + props.MedicalConditions.length + ')' : '(0)'}>
              {props.title}
            </Header>
          }
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default MedicalConditionsTable;
