/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { TableProps } from '@cloudscape-design/components';
import { useState, useEffect, useContext } from 'react';
import { IHighlight } from 'react-pdf-highlighter';
import { ApplicantDetailContext } from './ApplicantDetailContext';
import { CreateHighlightFromOffset } from './CreateHighlightFromOffset';

import MedicalConditionsTable, { MedicalProps, toTitleCase } from './MedicalConditionsTable';
interface TreatmentProps extends MedicalProps {
  Date: string;
}
export const TestTreatments = () => {
  // state
  const [MedicalConditions, setMedicalConditions] = useState<TreatmentProps[]>();

  const { TextractMapping, AddHighlights } = useContext(ApplicantDetailContext);

  useEffect(() => {
    const getTreatmentName = (item: { Category: string; Attributes: any[]; Text: any }) => {
      if (item.Category === 'TIME_EXPRESSION') {
        return item.Attributes.filter((attribute: { Type: string }) => attribute.Type === 'PROCEDURE_NAME')[0].Text;
      } else {
        return item.Text;
      }
    };

    const getComprehendClassification = async () => {
      const highlights: IHighlight[] = [];

      const medicationConditions = TextractMapping?.TestTreatments.map((entity: any) => {
        const highlight = CreateHighlightFromOffset(entity, 'Treatment');
        if (highlight) highlights.push(highlight);
        return {
          Text: getTreatmentName(entity),
          Category: entity.Category,
          Score: entity.Score,
          Negation: entity.Traits?.find((trait: { Name: string }) => trait.Name === 'NEGATION') !== undefined,
          Date: entity.Category === 'TIME_EXPRESSION' ? entity.Text : undefined,
          HighlightId: highlight?.id,
        };
      });
      medicationConditions?.sort((a, b): number => b.Score - a.Score);
      setMedicalConditions(medicationConditions);
      AddHighlights(highlights);
    };
    getComprehendClassification();
  }, [TextractMapping]);

  // table column definitions
  const columnDefinitions: TableProps.ColumnDefinition<TreatmentProps>[] = [
    {
      id: 'Text',
      header: 'Treatment',
      cell: (item: TreatmentProps) => toTitleCase(item.Text),
      minWidth: 50,
    },
    {
      id: 'Date',
      header: 'Date',
      cell: (item: TreatmentProps) => item.Date,
      minWidth: 180,
    },
    {
      id: 'Confidence',
      header: 'Confidence',
      cell: (item: TreatmentProps) => `${(item.Score * 100).toFixed(2)}%`,
      minWidth: 180,
    },
  ];
  return (
    <MedicalConditionsTable
      MedicalConditions={MedicalConditions}
      title="Test Treatment"
      columnDefinitions={columnDefinitions}
    />
  );
};
