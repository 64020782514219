/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { Storage } from 'aws-amplify';

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getDocumentPreviewUrl = async (filePath: string) => {
  let url = '';
  if (filePath.split('.').pop() === 'png') {
    url = await Storage.get(`uploads${filePath.split('/uploads')[1]}`, { level: 'public' });
  } else {
    url = await Storage.get(`processed/images${filePath.split('/uploads')[1]}.png.large.png`, { level: 'public' });
  }
  return url;
};

export const getDocumentUrl = async (filePath: string) => {
  const keyIndex = Math.max(filePath.indexOf('/uploads'), filePath.indexOf('/processed'));
  const key = filePath.substring(keyIndex + 1);
  return await Storage.get(key, { level: 'public' });
};

// the processed data is assumed to be in processed folder in s3 bucket
const formatS3Key = (key: string) => {
  if (key.includes('s3://')) {
    return `processed${key.split('/processed')[1]}`;
  } else {
    return key;
  }
};

export const getDocumentAsJSON = async (key: string) => {
  const result = await Storage.get(formatS3Key(key), { contentType: 'text/plain', download: true, level: 'public' });
  if (result.Body) {
    var jsonText = await (result.Body as Blob).text();
    try {
      return JSON.parse(jsonText);
    } catch (err) {
      return jsonText;
    }
  } else {
    return {};
  }
};
