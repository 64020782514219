/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { ComprehendMedicalTextractMapping } from '@aws-prototype/shared/dist/libs/data-access/interfaces';
import { IHighlight } from 'react-pdf-highlighter';
var uuid = require('uuid');

export const CreateHighlightFromOffset = (
  entity: ComprehendMedicalTextractMapping,
  classificationType: string,
): IHighlight | undefined => {
  if ((entity.Mappings?.length ?? 0) === 0) return undefined;

  const convertedMappings = entity.Mappings.map((mapping) => {
    return {
      x1: mapping.geometry[0].x,
      y1: mapping.geometry[0].y,
      x2: mapping.geometry[2].x,
      y2: mapping.geometry[2].y,
      width: 1,
      height: 1,
      pageNumber: mapping.page + 1,
    };
  });

  const returnVal = {
    content: {
      text: entity.Text,
    },
    position: {
      boundingRect: convertedMappings[0],
      rects: convertedMappings,
      pageNumber: convertedMappings[0].pageNumber,
    },
    comment: {
      text: `${classificationType} - ${entity.Text}`,
      emoji: '',
    },
    id: uuid.v4(),
  };
  return returnVal;
};
