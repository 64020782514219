/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { SpaceBetween, ColumnLayout, Header, Button, Container, Grid, Tabs } from '@cloudscape-design/components';
import { useParams, useNavigate } from 'react-router-dom';
import { Medications } from './Medications';
import { MedicalConditions } from './MedicalConditions';
import { TestTreatments } from './TestTreatments';
import { Physicians } from './Physicians';
import { getDocumentAsJSON, getDocumentUrl } from 'src/utils';
import { useQueryClient } from 'react-query';
import { AppInterface } from '@aws-prototype/shared';
import { ClaimDocTypes } from '@aws-prototype/shared/dist/libs/data-access/enums';
import { useEffect, useState } from 'react';
import PdfViewer from '../preview/PdfViewer';
import { ApplicantDetailContext, EntityMapping } from './ApplicantDetailContext';
import { IHighlight } from 'react-pdf-highlighter';
import { Loader } from '@aws-amplify/ui-react';
import { FaHospitalUser, FaPills } from 'react-icons/fa';
export interface SummaryDetails {
  ApplicantId?: string;
  DateRange?: { MinDate: Date; MaxDate: Date };
  DateUploaded?: Date;
  Category?: string;
  Status?: string;
  Tags?: string[];
}
interface TabProps {
  label: string;
  id: string;
  content: JSX.Element;
}
const ApplicantDetail = () => {
  const { claimid } = useParams();
  const [Highlights, setHighlights] = useState<IHighlight[]>([]);
  const [docData, setDocData] = useState<AppInterface.ClaimDoc | undefined>(undefined);
  const [TextractMapping, setTextractMapping] = useState<EntityMapping | undefined>(undefined);
  const [SelectedHighlightId, SetSelectedHighlightId] = useState<string>();
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [SummaryDetails, setSummaryDetails] = useState<SummaryDetails>({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const tabs: TabProps[] = [
    {
      id: 'Medical Props',
      label: 'Medical Conditions',
      content: <MedicalConditions />,
    },
    {
      id: 'Medications',
      label: 'Medications',
      content: <Medications />,
    },
    {
      id: 'Physicians',
      label: 'Physicians',
      content: <Physicians />,
    },
    {
      id: 'Treatments',
      label: 'Treatments',
      content: <TestTreatments />,
    },
  ];
  const getDocumentDetails = async () => {
    const insuranceDocs = queryClient.getQueriesData('idp-insurance-docs')[0][1] as Array<AppInterface.ClaimDoc | any>;
    // retrieve data from RQ cache
    const docData = insuranceDocs.find((item) => item.documentType.Name === ClaimDocTypes.APS);
    setDocData(docData);
    let DateRange;
    if (docData.dateRange) {
      DateRange = {
        MinDate: new Date(docData.dateRange.minDate),
        MaxDate: new Date(docData.dateRange.maxDate),
      };
    }
    setSummaryDetails({ ...SummaryDetails, ApplicantId: claimid, DateRange });
    if (docData === undefined || docData?.comprehendMedicalEntityTextractMappingOutput === undefined) {
      throw new Error('Missing data');
    }
    const entities = (await getDocumentAsJSON(docData.comprehendMedicalEntityTextractMappingOutput)) as EntityMapping;

    setTextractMapping(entities);
    if (docData?.uploadLocation === undefined) {
      throw new Error('Unable to locate pdf');
    }
    getDocumentUrl(docData.uploadLocation).then(setPdfUrl);
  };

  const AddHighlights = (items: IHighlight[]) => {
    setHighlights((oldHighlights) => [...oldHighlights, ...items]);
  };
  useEffect(() => {
    getDocumentDetails();
  }, [claimid]);
  const markComplete = () => { };
  const handleCancel = () => {
    navigate('/');
  };
  return (
    <ApplicantDetailContext.Provider
      value={{ TextractMapping, SelectedHighlightId, SetSelectedHighlightId, Highlights, AddHighlights }}
    >
      <SpaceBetween direction="vertical" size="xxl">
        <SummaryHeader />

        <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
          {pdfUrl && <PdfViewer PdfUrl={pdfUrl} Highlights={Highlights} SelectedHighlightId={SelectedHighlightId} />}
          {TextractMapping !== undefined ? (
            <div
              style={{
                overflow: 'auto',
                maxHeight: '65vh',
              }}
            >
              <Tabs tabs={tabs} />
            </div>
          ) : (
            <div>
              <Loader />
              <span>If this takes too long, this may be caused by an incorrectly processed document.</span>
            </div>
          )}
        </Grid>
      </SpaceBetween>
    </ApplicantDetailContext.Provider>
  );

  function SummaryHeader() {
    return (
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" onClick={markComplete}>
                  Mark Complete
                </Button>
              </SpaceBetween>
            }
          >
            Summary
          </Header>
        }
      >
        <ColumnLayout columns={3}>
          <span>Applicant ID: {SummaryDetails?.ApplicantId}</span>
          <span>
            APS Date Range:{' '}
            {docData?.dateRange
              ? docData?.dateRange?.minDate + ' to ' + docData?.dateRange?.maxDate
              : 'Date range not found'}
          </span>
          <span>Date Added: {new Date(docData?.uploadedAt!).toLocaleDateString()}</span>
          <span>
            Category: {docData?.reviewStatus} {docData?.onLifeSavingMedication && <FaPills size={25} color="#FF5959" />}{' '}
            {docData?.recentProcedure && <FaHospitalUser size={25} color="#FF5959" />}
          </span>
          <span>In Progress: {docData?.claimStatus} </span>
        </ColumnLayout>
      </Container>
    );
  }
};

export default ApplicantDetail;
