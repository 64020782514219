/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { TableProps } from '@cloudscape-design/components';
import { useState, useEffect, useContext } from 'react';
import { IHighlight } from 'react-pdf-highlighter';
import { ApplicantDetailContext } from './ApplicantDetailContext';
import { CreateHighlightFromOffset } from './CreateHighlightFromOffset';

import MedicalConditionsTable, {
  MedicalProps,
  toTitleCase,
} from './MedicalConditionsTable';
interface MedicalConditionProps extends MedicalProps {
  Site: string;
  Negation: boolean;
}
export const MedicalConditions = () => {
  // state
  const [MedicalConditions, setMedicalConditions] = useState<MedicalConditionProps[]>();

  const { TextractMapping, AddHighlights } = useContext(ApplicantDetailContext);

  const formattedCondition = (item: MedicalConditionProps) => {
    return <div style={{ textDecoration: item.Negation ? 'line-through' : '' }}>{toTitleCase(item.Text)}</div>;
  };

  useEffect(() => {
    const getComprehendClassification = async () => {
      const highlights: IHighlight[] = [];
      const medicalConditions: MedicalConditionProps[] = [];

      for (const entity of TextractMapping?.MedicalConditions ?? []) {
        const highlight = CreateHighlightFromOffset(entity, 'Condition');
        if (highlight) highlights.push(highlight);
        const newCondition = {
          Text: entity.Text,
          Category: entity.Category,
          Score: entity.Score,
          Negation: entity.Traits?.find((trait: { Name: string }) => trait.Name === 'NEGATION') !== undefined,
          Site: entity.Attributes?.filter((attribute: { Category: string }) => attribute.Category === 'ANATOMY')
            .map((attribute: { Text: any }) => toTitleCase(attribute.Text))
            .join(', '),
          HighlightId: highlight?.id,
        };
        medicalConditions.push(newCondition);
      }

      medicalConditions.sort((a, b): number => b.Score - a.Score);
      setMedicalConditions(medicalConditions);
      AddHighlights(highlights);
    };
    getComprehendClassification();
  }, [TextractMapping]);

  // table column definitions
  const columnDefinitions: TableProps.ColumnDefinition<MedicalConditionProps>[] = [
    {
      id: 'Text',
      header: 'Condition',
      cell: (item: MedicalConditionProps) => formattedCondition(item),
      minWidth: 50,
    },
    {
      id: 'Site',
      header: 'Site',
      cell: (item: MedicalConditionProps) => item.Site,
      minWidth: 180,
    },
    {
      id: 'Confidence',
      header: 'Confidence',
      cell: (item: MedicalConditionProps) => `${(item.Score * 100).toFixed(2)}%`,
      minWidth: 180,
    },
  ];
  return (
    <MedicalConditionsTable
      MedicalConditions={MedicalConditions}
      title="Medical Conditions"
      columnDefinitions={columnDefinitions}
    />
  );
};
