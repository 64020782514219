/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppConfigSliceInterface {
  showHelpMenu: boolean;
  helpKey: number;
}

// Define the initial state using that type
const initialState: AppConfigSliceInterface = {
  showHelpMenu: false,
  helpKey: 0,
};

const appConfigSlice = createSlice({
  name: 'appConfigFeatures',
  initialState: initialState,
  reducers: {
    showHelp: (state) => {
      state.showHelpMenu = true;
    },
    hideHelp: (state) => {
      state.showHelpMenu = false;
    },
    toggleHelp: (state) => {
      state.showHelpMenu = !state.showHelpMenu;
    },
    setHelpKey: (state, action: PayloadAction<number>) => {
      state.helpKey = action.payload;
    },
  },
});

export default appConfigSlice.reducer;
export const { showHelp, hideHelp, toggleHelp, setHelpKey } = appConfigSlice.actions;
