/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/

import {
  Alert,
  Spinner,
  Header,
  BreadcrumbGroup,
  Badge,
  BreadcrumbGroupProps,
  AppLayout,
  Box,
} from '@cloudscape-design/components';
// navigation
import { useNavigate, useParams } from 'react-router-dom';

// React query
import { useClaimDocs } from '../../hooks/useClaim';
import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';
import ApplicantDetail from '../components/applicant/ApplicantDetail';

const AnalyzeHealthcareClaim = () => {
  const navigate = useNavigate();
  // params
  const { claimid } = useParams();
  const { width } = useWindowDimensions();

  // RQ Fetch
  const { isError, isFetched, isFetching } = useClaimDocs(claimid!);

  const gotoPage = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const PageHeader = () => {
    return (
      <>
        <Header variant="h1">APS Document</Header>
        <Badge color="blue">{claimid}</Badge>
      </>
    );
  };

  const BCrumb = () => {
    return (
      <BreadcrumbGroup
        items={[
          { text: 'Home', href: '/' },
          { text: 'APS Document', href: '#components' },
        ]}
        ariaLabel="Breadcrumbs"
        expandAriaLabel="Show path"
        onFollow={(event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => gotoPage(event)}
      />
    );
  };

  if (isFetching) {
    return (
      <AppLayout
        contentType="wizard"
        minContentWidth={width - 300}
        navigationHide
        toolsHide
        breadcrumbs={<BCrumb />}
        disableContentHeaderOverlap
        content={
          <Box textAlign="center" variant="p">
            <Spinner size="large" />
          </Box>
        }
        contentHeader={<PageHeader />}
      />
    );
  }

  return (
    <AppLayout
      contentType="default"
      minContentWidth={width - 300}
      // disableContentPaddings
      navigationHide
      toolsHide
      breadcrumbs={<BCrumb />}
      content={
        <>
          {isError && (
            <Alert type="error" header="Unable to fetch Healthcare Documents">
              We are unable to fetch this document at this time. Please try again later.
            </Alert>
          )}
          {isFetched && (
            <div style={{ padding: '20px 0px' }}>
              <ApplicantDetail />
            </div>
          )}
        </>
      }
      contentHeader={<PageHeader />}
    />
  );
};

export default AnalyzeHealthcareClaim;
