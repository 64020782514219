/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { TableProps } from '@cloudscape-design/components';
import { useState, useEffect, useContext } from 'react';
import { IHighlight } from 'react-pdf-highlighter';
import { ApplicantDetailContext } from './ApplicantDetailContext';
import { CreateHighlightFromOffset } from './CreateHighlightFromOffset';

import MedicalConditionsTable, { MedicalProps, toTitleCase } from './MedicalConditionsTable';
interface MedicationProps extends MedicalProps {
  Dose: string;
  Frequency: string;
  Route: string;
  Strength: string;
  isLifeSaving: boolean;
}

export const Medications = () => {
  // state
  const [MedicalConditions, setMedicalConditions] = useState<MedicalProps[]>();

  const { TextractMapping, AddHighlights } = useContext(ApplicantDetailContext);

  useEffect(() => {
    const getComprehendClassification = async () => {
      const highlights: IHighlight[] = [];
      const medicationConditions = TextractMapping?.Medications.map((entity: any) => {
        const highlight = CreateHighlightFromOffset(entity, 'Medication');
        if (highlight) highlights.push(highlight);
        return {
          Text: entity.Text,
          Category: entity.Category,
          Score: entity.Score,
          Negation: entity.Traits?.find((trait: { Name: string }) => trait.Name === 'NEGATION') !== undefined,
          Dose: entity.Attributes?.find((attribute: { Type: string }) => attribute.Type === 'DOSAGE')?.Text,
          Route: entity.Attributes?.find((attribute: { Type: string }) => attribute.Type === 'ROUTE_OR_MODE')?.Text,
          Frequency: entity.Attributes?.find((attribute: { Type: string }) => attribute.Type === 'FREQUENCY')?.Text,
          isLifeSaving: entity.isLifeSaving,
          HighlightId: highlight?.id,
        };
      });
      medicationConditions?.sort((a, b): number => b.Score - a.Score);
      setMedicalConditions(medicationConditions);
      AddHighlights(highlights);
    };
    getComprehendClassification();
  }, [ TextractMapping]);

  // table column definitions
  const columnDefinitions: TableProps.ColumnDefinition<MedicationProps>[] = [
    {
      id: 'Text',
      header: 'Medication',
      cell: (item: MedicationProps) => toTitleCase(item.Text),
      minWidth: 50,
    },
    {
      id: 'isLifeSaving',
      header: 'Life Saving',
      cell: (item: MedicationProps) => (item.isLifeSaving ? 'Yes' : 'No'),
      minWidth: 180,
    },
    {
      id: 'Dose',
      header: 'Dose',
      cell: (item: MedicationProps) => toTitleCase(item.Dose),
      minWidth: 180,
    },
    {
      id: 'Frequency',
      header: 'Frequency',
      cell: (item: MedicationProps) => toTitleCase(item.Frequency),
      minWidth: 180,
    },
    {
      id: 'Route',
      header: 'Route',
      cell: (item: MedicationProps) => toTitleCase(item.Route),
      minWidth: 180,
    },
    {
      id: 'Strength',
      header: 'Strength',
      cell: (item: MedicationProps) => item.Strength,
      minWidth: 180,
    },
    {
      id: 'Confidence',
      header: 'Confidence',
      cell: (item: MedicationProps) => `${(item.Score * 100).toFixed(2)}%`,
      minWidth: 180,
    },
  ];
  return (
    <MedicalConditionsTable
      MedicalConditions={MedicalConditions}
      title="Medications"
      columnDefinitions={columnDefinitions}
    />
  );
};
