/**********************************************************************************************************************
 *  Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { useContext, useEffect, useRef, useState } from 'react';

import { PdfLoader, PdfHighlighter, Highlight, Popup, Tip } from 'react-pdf-highlighter';

import type { IHighlight, NewHighlight } from 'react-pdf-highlighter';

import { Spinner } from './Spinner';

import './style/PdfViewer.css';
import { ApplicantDetailContext } from '../applicant/ApplicantDetailContext';

const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  // document.location.hash = "";
};

const HighlightPopup = ({ comment }: { comment: { text: string; emoji: string } }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

interface PdfViewerProps {
  PdfUrl: string;
  Highlights: IHighlight[];
  SelectedHighlightId?: string;
}
const PdfViewer = (props: PdfViewerProps) => {
  const pdfEl = useRef<PdfLoader>(null);
  const [, setConvertedHighlights] = useState<IHighlight[]>([]);
  const [scrollViewerTo, setScrollViewerTo] = useState<(highlight: any) => any>(() => () => {});
  const {  AddHighlights } = useContext(ApplicantDetailContext);

  useEffect(() => {
    setConvertedHighlights(
      props.Highlights.map((highlight: IHighlight) => {
        return highlight;
      }),
    );
  }, [props.Highlights]);

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo?.(highlight);
    }
  };

  const getNextId = () => String(Math.random()).slice(2);
  
  useEffect(() => {
    window.addEventListener('hashchange', scrollToHighlightFromHash, false);
    return () => {
      window.removeEventListener('hashchange', scrollToHighlightFromHash);
    };
  });

  const addHighlight = (highlight: NewHighlight) => {

    console.log("Saving highlight", highlight);
    let newArray = [{...highlight, id: getNextId() }];
    AddHighlights(newArray)
    // setConvertedHighlights([{...highlight, id: getNextId() }, ...props.Highlights]);
  }

  const getHighlightById = (id: string) => {
    return props.Highlights?.find((highlight) => highlight.id === id);
  };

  const setHighlight = (pdfDocument: any) => {
    // setPdfDocument(pdfDocument);
    return (
      <PdfHighlighter
        pdfDocument={pdfDocument}
        enableAreaSelection={(event) => event.altKey}
        onScrollChange={resetHash}
        // pdfScaleValue="page-width"
        scrollRef={(scrollTo) => {
          setScrollViewerTo(() => scrollTo);

          scrollToHighlightFromHash();
        }}
        
        onSelectionFinished={(position, content, hideTipAndSelection, transformSelection) => (
          <Tip
            onOpen={transformSelection}
            onConfirm={(comment:any) => {
              addHighlight({ content, position, comment });

              hideTipAndSelection();
            }}
          />
        )}
        highlightTransform={(highlight, index, setTip, hideTip, viewportToScaled, screenshot, isScrolledTo) => {
          const component = (
            <Highlight isScrolledTo={isScrolledTo} position={highlight.position} comment={highlight.comment} />
          );

          return (
            <Popup
              popupContent={<HighlightPopup {...highlight} />}
              onMouseOver={(popupContent) => setTip(highlight, (highlight) => popupContent)}
              onMouseOut={hideTip}
              key={index}
              children={component}
            />
          );
        }}
        highlights={props.Highlights ?? []}
      />
    );
  };
  return (
    <PdfLoader url={props.PdfUrl} beforeLoad={<Spinner />} ref={pdfEl}>
      {setHighlight}
    </PdfLoader>
  );
};

export default PdfViewer;
